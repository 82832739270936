<template>
    <div>
        <v-dialog
            v-model="showDialogSupervisor"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Select a Supervisor
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Supervisor</label>
                            <v-combobox
                                v-model="userSelected"
                                :items="listUser"
                                item-text="name"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                single-line
                                outlined
                                dense
                            ></v-combobox>

                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },
			
            idStatusToUpdate: {
                type: Number,
                default: 0
            },

            setSupervisor: {
                default: null
            },

			showDialogSupervisor: {
				default: false
			},
		},

        watch: {
            async "setSupervisor"() {
				if (this.setSupervisor != null && this.setSupervisor != undefined) {
                    this.userSelected = this.setSupervisor;
                }
            },
        },
		
        data: () => ({
			
            listUser: [],
			
            userSelected: null,

            validations: {
                required: required
            },
		}),

        methods: {

            cancel() {
                this.$emit('update:showDialogSupervisor', false);
            },

            async updateStatus() {

                if (this.idStatusToUpdate != null && this.idStatusToUpdate != undefined && this.idStatusToUpdate != 0) {

                    let updateProjectStatus = {
                        id: this.id,
                        status: this.idStatusToUpdate
                    };

                    await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateProjectStatus);
                }
            },

            async confirm() {

                let idUser = 0;

                if (this.userSelected != null && this.userSelected != undefined) {
                    idUser = this.userSelected.id;
                }

                if (idUser == 0) {
                    this.showToast("error", "Error!", "Please, select a Supervisor to complete the action!");
                }
                else {
                
                    let updateProjectSupervisor = {
                        id: this.id,
                        idUser
                    };

                    let result = await this.$store.dispatch("serviceModule/SetProjectSupervisor", updateProjectSupervisor);

                    if (result.success) {
                        await this.updateStatus();
                        this.showToast("success", "Success!", "Supervisor assigned successfully!");
                    
                        this.$emit('methodConfirmToCall');
                        this.cancel();
                    }
                    this.cancel();
                }
            }
        },

        async created() {
            const listUserResponse = await this.$store.dispatch("userModule/List");
            this.listUser = listUserResponse.filter(usr => usr.constructionSupervisor == 1);
        }
    };
</script>